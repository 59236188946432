export const seoData = {
  homePage: {
    title: "Learn to build software for the modern web",
    metaDescription: "Developing full-stack applications using Gatsby, React, Node,TypeScript and Scala. We educate and mentor learners with online courses and programs.",
    pathname: "",
  },
  workPage: {
    title: "Work",
    metaDescription: "Industry experts in developing software products for startups and enterprises.  Creating world class digital content for learners and providing technical mentorship in software development.",
    pathname: "/work",
  },
  coursesPage: {
    title: "Online Programming Courses on JavaScript, Material-UI, and Scala ",
    metaDescription: "Watch online courses on JavaScript, Material-UI, and Scala created by Deeksha Sharma and Harit Himanshu. Our hands-on programming courses are perfect for beginners and experts to get better, and faster at coding.",
    pathname: "/courses",
  },
  teamPage: {
    title: "Deeksha Sharma and Harit Himanshu, Team of 2 full-stack engineers",
    metaDescription: "Deeksha & Harit have combined work experience of 20+ years in Software Development with companies such as Flexera,Yahoo,Nomis Solutions,Pluralsight,Chegg,Spark,Sunrun, VMWare, and Skyhigh.",
    pathname: "/team",
  },
  aboutPage: {
    title: "About bonsaiilabs - What we do and how we started",
    metaDescription: "At bonsaiilabs, we develop high quality software products for startups and provide technical mentoring to individuals and teams. We believe that complex problems are best solved when they visualized and broken into smaller pieces. We had great successes using this methodology while developing software and educating learners in our mentoring program.",
    pathname: "/about",
  },
  blogPage: {
    title: "Blog bonsaiilabs - Web Development Blog on JavaScript, React, Material-UI",
    metaDescription: "The bonsaiilabs web development and programming blog provides weekly video content on React, Material-UI, JavaScript and web development ecosystem for developers." ,
    pathname: "/blog",
  },
  materialUiWithReact: {
    title: "Material-UI online course for React Developers",
    metaDescription: "Learn to apply Google Material Design using Material-UI and React to build beautiful apps faster.",
    pathname: "/material-ui-with-react",
  },
  firstStepsWithScala: {
    title: "FREE Online Course to learn Scala",
    metaDescription: "Start your functional programming journey with Scala. The course educates on Immutability, Referential Transparency, Scala Type Hierarchy, Scala REPL, Functions, and overview of Scala Ecosystem.",
    pathname: "/first-steps-with-scala",
  },
  eventLoopInJs: {
    title: "Master Event Loop in JavaScript",
    metaDescription: "Understand the magic of event loop, browser threads, processes, ExecutionContext and asynchronous JavaScript",
    pathname: "/event-loop-javascript",
  },
  learnPage: {
    title: "Learn with bonsaiilabs",
    metaDescription: "Explore our collection of video and articles on modern web development and computer science",
    pathname: "/learn",
  },
  mentoringPage: {
    title: 'Mentoring - Learn with BonsaiiLabs',
    metaDescription: 'Acquire hands-on knowledge with us on modern technologies with our 1:1 offerings.',
    pathname: '/mentoring',
  },
  masteringJavaScript: {
    title: 'Mastering JavaScript - Mentoring program by BonsaiiLabs',
    metaDescription: 'Gain hands-on expertise on concepts such as Scopes, Promises, Prototypes, ES6/7/8/9, Functional Programming and more.',
    pathname: '/mastering-javascript',
  },
  reactFundamentals: {
    title: 'React Fundamentals - Mentoring program by BonsaiiLabs',
    metaDescription: 'Gain hands-on expertise on concepts such as JSX, States, PropTypes, Hooks, Refs, Higher-Order Components and more.',
    pathname: '/react-fundamentals',
  },
  reactRedux: {
    title: 'Learn to develop apps with React & Redux - Mentoring program by BonsaiiLabs',
    metaDescription: 'Gain hands-on expertise on React & Redux concepts such as JSX, States, ' +
      'PropTypes, Hooks, Refs, Higher-Order Components, Action Creators, Reducers, Dispatchers and more.',
    pathname: '/react-redux',
  },
  materialUi: {
    title: 'Learn to create beautiful apps with React & Material-UI - Mentoring program by BonsaiiLabs',
    metaDescription: 'Gain hands-on expertise on React & Material-UI using custom theme, grid system, layouts, theme overrides and more',
    pathname: '/material-ui',
  },
  fullStackJS: {
    title: 'Develop fullstack apps in JavaScript & NodeJS - Mentoring program by BonsaiiLabs',
    metaDescription: 'Gain hands-on expertise on JavaScript and NodeJS to develop and deploy fullstack JavaScript apps',
    pathname: '/fullstack-javascript',
  },
}
