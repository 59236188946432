import { Grid } from "@material-ui/core"
import React from "react"
import { PageTitles } from "../../shared/constants"
import PageTitleDescription from "../Common/PageTitleDescription"
import AllCourses from "./AllCourses"

export const Courses = ({
                          classes,
                          courses,
                          onCourseClick,
                          onMyCoursesClick,
                        }) => (
  <Grid container direction={"column"}>
    <PageTitleDescription
      title={PageTitles.courses.title}
      description={PageTitles.courses.description}
      classes={classes}
      callToActionLabel={"My Courses"}
      onCallToActionClick={onMyCoursesClick}
    />
    <AllCourses courses={courses} onCourseClick={onCourseClick} />
  </Grid>
)
