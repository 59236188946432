import React from "react"
import { Courses } from "../components/Courses"
import { CoursesData, ExternalRoutes } from "../shared/constants"
import Layout from "../components/layout"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"

export default class CoursesContainer extends React.Component {
  onCourseClick = id => {
    const urlPrefixForCourse = CoursesData.filter(course => course.id === id)[0]['urlPrefix'];
    const endPoint = urlPrefixForCourse ? `/${urlPrefixForCourse}/${id}` : `/${id}`;
    navigate(endPoint);
  }
  onMyCoursesClick = () => window.open(ExternalRoutes.myCourses, "_self")

  render() {
    const seo = seoData.coursesPage
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={2}>
          <Courses
            courses={CoursesData}
            onCourseClick={this.onCourseClick}
            onMyCoursesClick={this.onMyCoursesClick}
          />
        </Layout>
      </>
    )
  }
}
