import { Button, Grid, Typography, withStyles } from "@material-ui/core"
import * as React from "react"

interface PageTitleDescriptionProps {
  title: string;
  description?: string;
  callToActionLabel?: string;
  onCallToActionClick?: () => void;
  classes?: any
}

const PageTitleDescription = ({
                                classes,
                                title,
                                description,
                                callToActionLabel,
                                onCallToActionClick,
                              }: PageTitleDescriptionProps) => (
  <Grid container alignItems="center" direction="column" className={classes.root}>
    <Grid item xs={12}>
      <Typography paragraph variant={"h3"} align={"center"}>{title}</Typography>
    </Grid>
    <Grid item md={9} className={classes.descriptionItem}>
      {
        description && <Typography variant="subtitle1" color="textPrimary" align={"center"} className={classes.description}>
          {description}
        </Typography>
      }
    </Grid>
    {callToActionLabel && (
      <Grid item className={classes.callToActionItem}>
        <Button
          color={"primary"}
          variant={"contained"}
          onClick={onCallToActionClick}
          aria-label={callToActionLabel}
        >
          {callToActionLabel}
        </Button>
      </Grid>
    )}
  </Grid>
)

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.surface,
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(12),
    },
  },
  descriptionItem: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  callToActionItem: {
    paddingTop: theme.spacing(4),
  },
})
// @ts-ignore
export default withStyles(styles)(PageTitleDescription)
