import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import React from "react"
import Grid from "@material-ui/core/Grid"

const styles = theme => ({
  card: {
    width: 300,

  },
  media: {
    height: 300,
    [theme.breakpoints.down("md")]: {
      height: 200,
    },
  },
  courseTitle: {
    height: 80
  }
})

function CourseCard({ classes, id, name, level, image, duration, onCourseClick }) {
  return (
    <Card className={classes.card} onClick={() => onCourseClick(id)}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={image}
          title={name}
        />
        <CardContent>
          <Grid container className={classes.courseTitle}>
            <Typography gutterBottom variant="h6">
              {name}
            </Typography>
          </Grid>
          <Typography component="p" color='textSecondary'>{level} | {duration}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button aria-label="Learn More" size="small" color="primary" onClick={() => onCourseClick(id)}>
          Learn More
        </Button>
      </CardActions>
    </Card>
  )
}

CourseCard.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CourseCard)
