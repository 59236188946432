import React from "react"
import CourseCard from "./CourseCard"
import { Grid, withStyles } from "@material-ui/core"

const CoursesCards = ({ classes, courses, onCourseClick }) => (
  <Grid container justify="space-evenly">
    {courses.map((course, key) => (
      <div className={classes.courseCard} key={key}>
        <CourseCard
          id={course.id}
          name={course.name}
          level={course.level}
          duration={course.duration}
          image={course.img}
          onCourseClick={onCourseClick}
        />
      </div>
    ))}
  </Grid>
)

const styles = theme => ({
  courseCard: {
    [theme.breakpoints.only("xs")]: {
      paddingBottom: theme.spacing(6),
    },
  },
})

export default withStyles(styles)(CoursesCards)
