import React from "react"
import CoursesCards from "../Common/CoursesCards"
import SectionP from "../Common/SectionP"

export default function AllCourses({ classes, courses, onCourseClick }) {
  return (
    <SectionP>
      <CoursesCards courses={courses} onCourseClick={onCourseClick} />
    </SectionP>
  )
}
